import boot from './boot/boot.spell';

const
    relay = window.relay = window.relay || {},

    dataLayer = window.dataLayer = window.dataLayer || [],
    gtag = window.gtag = window.gtag || function() {
        dataLayer.push(arguments);
    },

    script = (src, error) => {
        let loaded = false;
        const
            script = document.createElement('script'),
            first = document.getElementsByTagName('script')[0];

        script.async = 'async';
        script.onload = script.onreadystatechange = function() {
            const state = this.readyState;

            if (!loaded && (!state || state === 'complete' || state === 'loaded')) {
                loaded = true;
            }
        };
        script.onerror = script.onabort = () => {
            if (!loaded) {
                loaded = true;
                if (error) {
                    error();
                }
            }
        };
        script.src = src;
        first.parentNode.insertBefore(script, first);
    },

    clear = name => {
        const doc = document.documentElement;

        doc.className = (
            doc.className.replace(
                new RegExp(`\\b${name}\\b`, 'g'),
                ''));
    },

    fonts = () => {
        let timeout = null;
        const
            cancel = () => {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
            },
            done = () => {
                clear('relay-fonts-loading');
                cancel();
            };

        window.WebFontConfig = {
            classes: false,
            active: done,
            inactive: done,
            typekit: {
                id: boot.fonts.typekit
            }
        };
        timeout = setTimeout(done, 1000);
        script('//ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js');
    },

    optimize = () => {
        let timeout = null,
            registrants = [];
        const
            query = window.location.search || '',
            register = registrant => {
                if (registrants) {
                    registrants.push(registrant);
                } else {
                    setTimeout(
                        () => {
                            notify([registrant], window.google_optimize || null);
                        },
                        0);
                }
            },
            notify = (registrants, optimize) => {
                for (let i = 0, length = registrants.length; i < length; i += 1) {
                    registrants[i](optimize);
                }
            },
            cancel = () => {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
            },
            end = () => {
                clear('relay-optimize-loading');
                cancel();
            },
            config = {
                start: new Date().getTime(),
                end: () => {
                    const registered = registrants;

                    end();
                    config.end = null;
                    registrants = null;
                    notify(registered, window.google_optimize || null);
                },
                [boot.optimize.container]: true
            };

        if (!/^[?&]optimize=false(?:&|$)/.test(query) && (
                boot.env === 'prod' ||
                /^[?&]optimize=true(?:&|$)/.test(query))) {

            dataLayer.hide = config;
            timeout = setTimeout(end, 1000);
            gtag('js', new Date());
            gtag(
                'config',
                boot.optimize.property,
                {
                    send_page_view: false,
                    optimize_id: boot.optimize.container
                });
            script(`//www.googletagmanager.com/gtag/js?id=${boot.optimize.property}`, config.end);
        } else {
            end();
            registrants = null;
        }
        relay.optimize = register;
    };

// Adapted from https://documentation.sourcepoint.com/web-implementation/sourcepoint-setup-and-ccpa-configuration
window.__uspapi = (
    () => {
        const
            frame = document.createElement('iframe'),
            uspapi = function(command, version, callback) {
                if (!arguments.length) {
                    return window.__uspapi.a;
                } else if (command === 'ping') {
                    callback(
                        {
                            gdprAppliesGlobally: false,
                            cmpLoaded: false
                        },
                        true);
                } else {
                    window.__uspapi.a.push([].slice.apply(arguments));
                }
            };

        frame.style.cssText = 'display:none';
        frame.name = '__uspapiLocator';
        document.body.appendChild(frame);

        uspapi.a = [];
        uspapi.msgHandler = message => {
            var isString = typeof message.data === 'string';

            try {
                const data = isString ? JSON.parse(message.data) : message.data;

                if (data.__cmpCall) {
                    const {command, parameter, callId} = data.__cmpCall;

                    window.__uspapi(
                        command,
                        parameter,
                        (returnValue, success) => {
                            const
                                data = {
                                    __cmpReturn: {
                                        returnValue,
                                        success,
                                        callId
                                    }
                                };

                            message.source.postMessage(
                                isString ?
                                    JSON.stringify(data) :
                                    data,
                                '*');
                        });
                }
            } catch (error) {}
        };
        window.addEventListener('message', uspapi.msgHandler, false);
        return uspapi;
    })();

fonts();
optimize();
